import { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Datepicker } from 'flowbite-react';
import { supabaseClient } from '../../config/supabase-clients';
import { useAuth } from '../../hooks/useAuth';

const supabase = supabaseClient;

dayjs.extend(weekOfYear);
dayjs.locale('es');

export const TeleWorking = () => {
    const { userPublicData } = useAuth();
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [selectedDate, setSelectedDate] = useState(null);
    const [events, setEvents] = useState([]);
    const [teleworkingDays, setTeleworkingDays] = useState([]);
    const [vacationDays, setVacationDays] = useState([]);
    const [holidayDays, setHolidayDays] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const startOfMonth = useMemo(() => currentDate.startOf('month'), [currentDate]);
    const endOfMonth = useMemo(() => currentDate.endOf('month'), [currentDate]);
    const daysInMonth = useMemo(() => currentDate.daysInMonth(), [currentDate]);

    const fetchTeleworkingDays = useCallback(async () => {
        const { data: teleworkingData, error: teleworkingError } = await supabase
            .from('teleworking')
            .select('*');

        if (teleworkingError) {
            console.error('Error fetching teleworking days:', teleworkingError);
        } else if (teleworkingData) {
            const formattedTeleworkingDays = teleworkingData
                .filter(day => canSelectTeleworkingDay(day.teleworking_date))
                .map(day => ({
                    date: dayjs(day.teleworking_date).format('YYYY-MM-DD'),
                    user: {
                        user_id: day.user_id,
                        name: day.user_name,
                    },
                    type: 'teleworking'
                }));

            setTeleworkingDays(formattedTeleworkingDays);
            setEvents(prevEvents => [
                ...prevEvents.filter(event => !formattedTeleworkingDays.some(newEvent => newEvent.date === event.date)),
                ...formattedTeleworkingDays
            ]);
        }
    }, []);

    const updateEvents = useCallback((teleworking, vacation, holiday) => {
        const newEvents = [
            ...teleworking,
            ...vacation,
            ...holiday
        ];
        setEvents(newEvents);
    }, []);

    useEffect(() => {
        updateEvents(teleworkingDays, vacationDays, holidayDays);
    }, [teleworkingDays, vacationDays, holidayDays, updateEvents]);

    useEffect(() => {
        fetchTeleworkingDays();
    }, [fetchTeleworkingDays]);

    useEffect(() => {
        const fetchCalendarData = async () => {
            const { data: holidaysData, error: holidaysError } = await supabase
                .from('holidays')
                .select('*');

            if (holidaysError) {
                console.error('Error fetching holidays:', holidaysError);
            } else if (holidaysData) {
                const formattedHolidays = holidaysData.map(holiday => ({
                    date: dayjs(holiday.holiday_date).format('YYYY-MM-DD'),
                    description: "🎉 " + holiday.name,
                    type: 'holiday'
                }));
                setHolidayDays(formattedHolidays);
            }

            const { data: vacationData, error: vacationError } = await supabase
                .from('vacations')
                .select('*');

            if (vacationError) {
                console.error('Error fetching vacation days:', vacationError);
            } else if (vacationData) {
                let formattedVacationDays = [];
                vacationData.forEach(vacation => {
                    if (vacation.user_id === userPublicData.user_id) {
                        let current = dayjs(vacation.start_date);
                        const end = dayjs(vacation.end_date);
                        while (current.isBefore(end) || current.isSame(end, 'day')) {
                            formattedVacationDays.push({
                                date: current.format('YYYY-MM-DD'),
                                description: "🏖️ Vacaciones",
                                type: 'vacation'
                            });
                            current = current.add(1, 'day');
                        }
                    }
                });
                setVacationDays(formattedVacationDays);
            }

            await fetchTeleworkingDays();
        };

        fetchCalendarData();
    }, [userPublicData.user_id, fetchTeleworkingDays]);

    const handleSelectTeleworkingDay = useCallback(async (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        const existingEventIndex = teleworkingDays.findIndex(day => day.date === formattedDate && day.user.user_id === userPublicData.user_id);

        if (existingEventIndex !== -1) {
            const updatedTeleworkingDays = teleworkingDays.filter((_, index) => index !== existingEventIndex);
            setTeleworkingDays(updatedTeleworkingDays);
            updateEvents(updatedTeleworkingDays, vacationDays, holidayDays);

            const { error } = await supabase
                .from('teleworking')
                .delete()
                .eq('teleworking_date', formattedDate)
                .eq('user_id', userPublicData.user_id);

            if (error) {
                console.error('Error eliminando el día de teletrabajo:', error);
            }
        } else {
            if (canSelectTeleworkingDay(formattedDate)) {
                const newTeleworkingDay = {
                    date: formattedDate,
                    user: {
                        user_id: userPublicData.user_id,
                        name: userPublicData.first_name,
                        avatar_url: userPublicData.avatar_url
                    },
                    type: 'teleworking'
                };
                const updatedTeleworkingDays = [...teleworkingDays, newTeleworkingDay];
                setTeleworkingDays(updatedTeleworkingDays);
                updateEvents(updatedTeleworkingDays, vacationDays, holidayDays);

                const { error } = await supabase
                    .from('teleworking')
                    .insert([{
                        teleworking_date: formattedDate,
                        user_id: userPublicData.user_id,
                        user_name: userPublicData.first_name,
                    }]);

                if (error) {
                    console.error('Error insertando el día de teletrabajo:', error);
                }
            } else {
                alert('No se puede seleccionar este día para teletrabajo debido a las restricciones.');
            }
        }
        closeModal();
    }, [teleworkingDays, userPublicData, vacationDays, holidayDays, updateEvents]);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleDayClick = useCallback((date) => {
        setSelectedDate(date);
        openModal();
    }, [openModal]);

    const isMonday = useCallback((date) => {
        return dayjs(date).day() === 1;
    }, []);

    const isWeekend = useCallback((date) => {
        const day = dayjs(date).day();
        return day === 0 || day === 6;
    }, []);

    const isAdjacentToVacation = useCallback((date) => {
        const dateObj = dayjs(date);
        return vacationDays.some(vacationDay => {
            const vacationDate = dayjs(vacationDay.date);
            if (isWeekend(vacationDate)) return false;
            return dateObj.diff(vacationDate, 'day') === 1 || vacationDate.diff(dateObj, 'day') === 1;
        });
    }, [vacationDays, isWeekend]);

    const isAdjacentToHoliday = useCallback((date) => {
        const dateObj = dayjs(date);
        return holidayDays.some(holidayDay => {
            const holidayDate = dayjs(holidayDay.date);
            if (isWeekend(holidayDate)) return false;
            return dateObj.diff(holidayDate, 'day') === 1 || holidayDate.diff(dateObj, 'day') === 1;
        });
    }, [holidayDays, isWeekend]);

    const getWeekNumber = useCallback((date) => {
        return dayjs(date).week();
    }, []);

    const hasReachedWeeklyLimit = useCallback((date) => {
        const weekNumber = getWeekNumber(date);
        const teleworkingDaysThisWeek = teleworkingDays.filter(day =>
            getWeekNumber(day.date) === weekNumber &&
            day.user.user_id === userPublicData.user_id
        );
        return teleworkingDaysThisWeek.length >= 1;
    }, [teleworkingDays, userPublicData.user_id, getWeekNumber]);

    const canSelectTeleworkingDay = useCallback((date) => {
        return !isMonday(date) &&
            !isWeekend(date) &&
            !isAdjacentToHoliday(date) &&
            !isAdjacentToVacation(date) &&
            !hasReachedWeeklyLimit(date) &&
            !holidayDays.some(day => day.date === dayjs(date).format('YYYY-MM-DD')) &&
            !vacationDays.some(day => day.date === dayjs(date).format('YYYY-MM-DD'));
    }, [isMonday, isWeekend, isAdjacentToHoliday, isAdjacentToVacation, hasReachedWeeklyLimit, holidayDays, vacationDays]);

    const prevMonth = useCallback(() => {
        setCurrentDate(currentDate.subtract(1, 'month'));
    }, [currentDate]);

    const nextMonth = useCallback(() => {
        setCurrentDate(currentDate.add(1, 'month'));
    }, [currentDate]);

    const datePickerCalculate = useCallback((date) => {
        setCurrentDate(dayjs(date, 'MM/YYYY'));
    }, []);

    const goToToday = useCallback(() => {
        setCurrentDate(dayjs());
    }, []);

    const days = useMemo(() => {
        const daysArray = [];
        const startDayOfWeek = startOfMonth.day();
        for (let i = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; i > 0; i--) {
            daysArray.push({
                date: startOfMonth.subtract(i, 'day'),
                currentMonth: false
            });
        }

        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push({
                date: dayjs(`${currentDate.year()}-${currentDate.month() + 1}-${i}`),
                currentMonth: true
            });
        }

        const totalDays = daysArray.length;
        const remainingCells = 42 - totalDays;
        for (let i = 1; i <= remainingCells; i++) {
            daysArray.push({
                date: endOfMonth.add(i, 'day'),
                currentMonth: false
            });
        }

        return daysArray;
    }, [startOfMonth, endOfMonth, daysInMonth, currentDate]);

    return (
        <div className="flex flex-col h-full dark:bg-gray-900">
            <header className="flex items-center justify-between border-gray-200 px-4 py-2 sm:px-6 sm:py-4 lg:flex-none dark:border-gray-700 rounded-t-lg bg-white dark:bg-gray-900">
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
                    <time dateTime={currentDate.format('YYYY-MM')}>{currentDate.format('MMMM YYYY').charAt(0).toUpperCase() + currentDate.format('MMMM YYYY').slice(1)}</time>
                </h1>
                <div className="flex items-center space-x-2 sm:space-x-4">
                    <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-gray-800 dark:shadow-md">
                        <Datepicker value={currentDate.format('MM/YYYY')} onSelectedDateChanged={datePickerCalculate} showClearButton={false} labelTodayButton='Hoy' language="es-ES" weekStart={1} />
                    </div>
                    <div className="flex items-center space-x-1 sm:space-x-2">
                        <button
                            type="button"
                            onClick={prevMonth}
                            className="flex h-8 w-8 sm:h-9 sm:w-9 items-center justify-center rounded-full border border-gray-300 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                        >
                            <svg className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                            </svg>
                        </button>
                        <button
                            type="button"
                            onClick={goToToday}
                            className="hidden sm:block border border-gray-300 px-2.5 sm:px-3.5 text-xs sm:text-sm font-semibold text-gray-900 rounded-md hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-100 dark:hover:bg-gray-700 py-1.5 sm:py-2"
                        >
                            Hoy
                        </button>
                        <button
                            type="button"
                            onClick={nextMonth}
                            className="flex h-8 w-8 sm:h-9 sm:w-9 items-center justify-center rounded-full border border-gray-300 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none dark:border-gray-600 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-700"
                        >
                            <svg className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                </div>
            </header>
            <div className="shadow ring-1 ring-black ring-opacity-5 flex flex-auto flex-col dark:ring-gray-700 rounded-b-lg rounded-xl overflow-hidden">
                <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 flex-none dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300">
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Lun</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Mar</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Mié</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Jue</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Vie</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Sáb</div>
                    <div className="flex justify-center bg-white py-2 dark:bg-gray-800">Dom</div>
                </div>
                <div className="flex bg-gray-200 text-sm leading-1 text-gray-700 flex-auto dark:bg-gray-900 dark:text-gray-300">
                    <div className="w-full grid grid-cols-7 grid-rows-6 gap-0.5">
                        {days.map(({ date, currentMonth }) => {
                            const dayEvents = events.filter(event => event.date === date.format('YYYY-MM-DD'));
                            const isPastDay = date.isBefore(dayjs(), 'day');
                            const isMondayDay = isMonday(date.format('YYYY-MM-DD'));
                            const isVacationDay = vacationDays.some(day => day.date === date.format('YYYY-MM-DD'));
                            const isHolidayDay = holidayDays.some(day => day.date === date.format('YYYY-MM-DD'));
                            const isWeekendDay = isWeekend(date.format('YYYY-MM-DD'));
                            const isAdjacentDay = isAdjacentToVacation(date) || isAdjacentToHoliday(date);

                            const isUserTeleworkingDay = teleworkingDays.some(day =>
                                day.date === date.format('YYYY-MM-DD') &&
                                day.user.user_id === userPublicData.user_id
                            );

                            const handleClick = () => {
                                if (!isPastDay && !isMondayDay && !isHolidayDay && !isVacationDay && !isWeekendDay) {
                                    handleDayClick(date.format('YYYY-MM-DD'));
                                }
                            };
                            return (
                                <div
                                    key={date.format('YYYY-MM-DD')}
                                    className={`relative h-24 sm:h-36 overflow-y-auto scrollbar rounded-sm scrollbar-thumb-primary-200 scrollbar-w-1 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full 
                                        ${date.isSame(dayjs(), 'day')
                                            ? 'bg-primary-500 text-white'
                                            : currentMonth
                                                ? isVacationDay || isHolidayDay
                                                    ? 'bg-green-100 dark:bg-green-800'
                                                    : isAdjacentToVacation(date) || isAdjacentToHoliday(date)
                                                        ? 'bg-red-100 dark:bg-red-900 opacity-80'
                                                        : 'bg-white'
                                                : 'bg-gray-100 text-gray-400'
                                        } relative px-2 sm:px-3 py-2 sm:py-4 dark:${date.isSame(dayjs(), 'day')
                                            ? 'bg-primary-400 dark:bg-primary-900 text-white'
                                            : currentMonth
                                                ? isVacationDay
                                                    ? 'bg-green-800'
                                                    : isAdjacentDay
                                                        ? 'bg-red-800'
                                                        : 'bg-gray-800'
                                                : 'bg-gray-700 text-gray-500'
                                        } ${isPastDay || isMondayDay ? 'opacity-70' : 'hover:bg-blue-200 dark:hover:bg-blue-700 transition duration-300 ease-in-out'}
                                        ${isUserTeleworkingDay ? 'bg-blue-100 dark:bg-blue-900' : ''}`}
                                    onClick={handleClick}
                                >
                                    <time dateTime={date.format('YYYY-MM-DD')} className="absolute top-1 left-1">
                                        {date.format('D')}
                                    </time>
                                    {isUserTeleworkingDay && (
                                        <div className="absolute top-1 right-1 w-2 h-2 bg-blue-500 rounded-full"></div>
                                    )}
                                    <div className='overflow-y-auto scrollbar scrollbar-thumb-primary-200 scrollbar-w-1 dark:scrollbar-thumb-gray-700 scrollbar-thumb-rounded-full'>
                                        {dayEvents.map((event, index) => (
                                            <div key={index} className="mt-1 ml-1 text-xs dark:text-gray-200">
                                                <div className="flex items-center">
                                                    {event.type === 'teleworking' && event.user && event.user.avatar_url && (
                                                        <img src={event.user.avatar_url} alt="avatar" className="w-4 h-4 sm:w-6 sm:h-6 rounded-full mr-1" />
                                                    )}
                                                    <div>
                                                        {event.type === 'teleworking' && event.user
                                                            ? `${event.user.name}`
                                                            : event.description}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} onClose={closeModal} transition className="transition duration-300 z-50 fixed ease-out data-[closed]:opacity-0">
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <DialogPanel className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-md">
                        <DialogTitle>
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">Seleccionar día de teletrabajo</h2>
                        </DialogTitle>
                        <p className="text-sm text-gray-700 dark:text-gray-300 py-2 mb-4">
                            {canSelectTeleworkingDay(selectedDate) ? (
                                `¿Estás seguro que quieres marcar el día ${dayjs(selectedDate).format('DD [de] MMMM [de] YYYY')} como teletrabajo?`
                            ) : (
                                <>
                                    <p>No puedes seleccionar este día para teletrabajo debido a las restricciones.</p>
                                    {isMonday(selectedDate) && <p>Los lunes no se pueden seleccionar.</p>}
                                    {isWeekend(selectedDate) && <p>Los fines de semana no se pueden seleccionar.</p>}
                                    {isAdjacentToVacation(selectedDate) && <p>Este día está adyacente a tus vacaciones.</p>}
                                    {isAdjacentToHoliday(selectedDate) && <p>Este día está adyacente a un día festivo.</p>}
                                    {hasReachedWeeklyLimit(selectedDate) && <p>Ya has seleccionado un día de teletrabajo esta semana.</p>}
                                    {/* Si es vacaciones o festivo no se puede seleccionar */}
                                    {holidayDays.includes(selectedDate) && <p>Este día es festivo.</p>}
                                    {vacationDays.includes(selectedDate) && <p>Este día es vacaciones.</p>}
                                </>
                            )}
                        </p>
                        <div className="flex items-center justify-between">
                            <Button
                                onClick={() => handleSelectTeleworkingDay(selectedDate)}
                                className={`flex items-center justify-center w-full py-2 rounded-md text-white font-semibold focus:outline-none ${teleworkingDays.some(day => day.date === selectedDate && day.user.user_id === userPublicData.user_id)
                                    ? 'bg-red-500 hover:bg-red-600'
                                    : canSelectTeleworkingDay(selectedDate)
                                        ? 'bg-primary-500 hover:bg-primary-600'
                                        : 'bg-gray-400 cursor-not-allowed'
                                    }`}
                                disabled={!canSelectTeleworkingDay(selectedDate) && !teleworkingDays.some(day => day.date === selectedDate && day.user.user_id === userPublicData.user_id)}
                            >
                                {teleworkingDays.some(day => day.date === selectedDate && day.user.user_id === userPublicData.user_id)
                                    ? 'Quitar teletrabajo'
                                    : 'Marcar como teletrabajo'}
                            </Button>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>

        </div>
    );

};