import { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon, XCircleIcon, PlusIcon } from "@heroicons/react/20/solid";
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * EditableTable Component
 * 
 * This component renders a table with editable rows. It supports adding, updating, and deleting rows.
 * 
 * Props:
 * - columns: Array of column definitions. Each column should have the following properties:
 *   - label: The display name of the column.
 *   - field: The key of the data object corresponding to this column.
 *   - type: The type of input for this column ('text', 'number', 'date', 'select').
 *   - options: (Optional) Array of options for 'select' type columns. Each option should have 'label' and 'value'.
 *   - width: (Optional) The width of the column.
 *   - editable: (Optional) Boolean indicating if the column is editable.
 * - initialData: Array of initial data to populate the table.
 * - deletable: Boolean indicating if rows can be deleted.
 * - maxRows: Maximum number of rows allowed in the table.
 * - onUpdateRow: Function to call when a row is updated.
 * - onAddRow: Function to call when a new row is added.
 * - onDeleteRow: Function to call when a row is deleted.
 * 
 * Example usage:
 * 
 * const columns = [
 *   { label: 'Name', field: 'name', type: 'text', editable: true },
 *   { label: 'Age', field: 'age', type: 'number', editable: true },
 *   { label: 'Birthday', field: 'birthday', type: 'date', editable: true },
 *   { label: 'Gender', field: 'gender', type: 'select', options: [{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }], editable: true }
 * ];
 * 
 * const initialData = [
 *   { id: 'row-1', name: 'John Doe', age: 30, birthday: '01/01/1991', gender: 'male' },
 *   { id: 'row-2', name: 'Jane Smith', age: 25, birthday: '02/02/1996', gender: 'female' }
 * ];
 * 
 * const handleUpdateRow = (updatedRow) => {
 *   console.log('Row updated:', updatedRow);
 * };
 * 
 * const handleAddRow = (newRow) => {
 *   console.log('Row added:', newRow);
 * };
 * 
 * const handleDeleteRow = (deletedRow) => {
 *   console.log('Row deleted:', deletedRow);
 * };
 * 
 * <EditableTable
 *   columns={columns}
 *   initialData={initialData}
 *   deletable={true}
 *   maxRows={10}
 *   onUpdateRow={handleUpdateRow}
 *   onAddRow={handleAddRow}
 *   onDeleteRow={handleDeleteRow}
 * />
 */

const EditableTable = forwardRef(({
    columns,
    initialData,
    deletable,
    maxRows = Infinity,
    onUpdateRow,
    onAddRow,
    onDeleteRow
}, ref) => {
    const [data, setData] = useState(initialData);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [newRowEditing, setNewRowEditing] = useState(false);
    const rowIdCounter = useRef(initialData.length);

    useImperativeHandle(ref, () => ({
        getAllRows: () => data
    }));

    const handleEditChange = (field, value) => {
        setEditValues({
            ...editValues,
            [field]: value
        });
    };

    const updateRow = (index, updatedRow) => {
        const newData = [...data];
        newData[index] = { ...newData[index], ...updatedRow };
        setData(newData);
        if (newRowEditing) {
            onAddRow(newData[index]);
        } else {
            onUpdateRow(newData[index]);
        }
        setEditingIndex(null);
        setNewRowEditing(false);
    };

    const deleteRow = (index) => {
        const rowToDelete = data[index];
        setData(prevData => prevData.filter((_, i) => i !== index));
        onDeleteRow(rowToDelete);
        setNewRowEditing(false);
    };

    const addRow = () => {
        const newRow = columns.reduce((acc, col) => ({ ...acc, [col.field]: '' }), { id: `row-${rowIdCounter.current}` });
        setData(prevData => [...prevData, newRow]);
        setEditingIndex(data.length);
        setEditValues(newRow);
        setNewRowEditing(true);
        rowIdCounter.current += 1;
    };

    const renderInputField = (column, rowData, index) => {
        if (editingIndex !== index || (column.editable === false && !newRowEditing)) {
            return rowData[column.field];
        }

        const commonClasses = "w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 transition-all duration-200 ease-in-out";

        switch (column.type) {
            case 'text':
            case 'number':
                return (
                    <input
                        type={column.type}
                        onChange={(e) => handleEditChange(column.field, e.target.value)}
                        className={commonClasses}
                        defaultValue={rowData[column.field]}
                    />
                );
            case 'date': {
                const [day, month, year] = rowData[column.field].split('/');
                const dateValue = `${year}-${month}-${day}`;
                return (
                    <input
                        type="date"
                        onChange={(e) => handleEditChange(column.field, e.target.value)}
                        className={commonClasses}
                        defaultValue={dateValue}
                    />
                );
            }
            case 'select':
                return (
                    <select
                        onChange={(e) => handleEditChange(column.field, e.target.value)}
                        className={commonClasses}
                        defaultValue={rowData[column.field]}
                    >
                        {column.options.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            default:
                return rowData[column.field];
        }
    };

    const renderRow = (rowData, index) => (
        <motion.tr
            layout
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="bg-white dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
            key={`${rowData.id}-${index}`}
        >
            {columns.map((column, colIndex) => (
                <td className={`px-6 py-4 ${column.width || ''}`} key={`${rowData.id}-${colIndex}`}>
                    {renderInputField(column, rowData, index)}
                </td>
            ))}
            <td className="px-6 py-4 flex justify-end">
                {editingIndex === index ? (
                    <div className="flex space-x-2 py-2">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            type="button"
                            className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-600"
                            onClick={() => {
                                if (newRowEditing) {
                                    deleteRow(index);
                                } else {
                                    setEditingIndex(null);
                                    setNewRowEditing(false);
                                }
                            }}
                        >
                            <XMarkIcon className="h-7 w-7" />
                        </motion.button>
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            type="button"
                            className="text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-600"
                            onClick={() => {
                                updateRow(index, editValues);
                            }}
                        >
                            <CheckIcon className="h-7 w-7" />
                        </motion.button>
                    </div>
                ) : (
                    <div className="flex space-x-2">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            type="button"
                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-600"
                            onClick={() => {
                                setEditingIndex(index);
                                setEditValues(rowData);
                            }}
                        >
                            <PencilIcon className="h-5 w-5" />
                        </motion.button>
                        {deletable && (
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                type="button"
                                className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-600"
                                onClick={() => deleteRow(index)}
                            >
                                <TrashIcon className="h-5 w-5" />
                            </motion.button>
                        )}
                    </div>
                )}
            </td>
        </motion.tr>
    );

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        {columns.map((column, index) => (
                            <th scope="col" className={`px-6 py-3 ${column.width || ''}`} key={index}>{column.label}</th>
                        ))}
                        <th scope="col" className="px-6 py-3 text-right">Acciones</th>
                    </tr>
                </thead>
                <AnimatePresence>
                    <tbody>
                        {data.length === 0 ? (
                            <tr className="bg-white dark:bg-gray-800">
                                <td colSpan={columns.length + 1}>
                                    <div className="flex flex-col justify-center items-center my-8 space-y-2">
                                        <XCircleIcon className="w-6 h-6 text-gray-400 dark:text-gray-600" />
                                        <span className="text-gray-500 dark:text-gray-400">No hay datos</span>
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            data.map((rowData, index) => renderRow(rowData, index))
                        )}
                    </tbody>
                </AnimatePresence>
            </table>
            {data.length < maxRows && (
                <div className="p-4">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        type='button'
                        className="w-full bg-primary-600 hover:bg-primary-700 text-white font-medium py-2 px-4 rounded-lg transition duration-200 ease-in-out flex justify-center items-center space-x-2"
                        onClick={addRow}
                    >
                        <PlusIcon className="w-5 h-5" />
                        <span>Agregar fila</span>
                    </motion.button>
                </div>
            )}
        </div>
    );
});

EditableTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            field: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['text', 'number', 'date', 'select']).isRequired,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired
                })
            ),
            width: PropTypes.string,
            editable: PropTypes.bool
        })
    ).isRequired,
    initialData: PropTypes.array.isRequired,
    maxRows: PropTypes.number,
    onUpdateRow: PropTypes.func,
    onAddRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    deletable: PropTypes.bool
};

EditableTable.displayName = 'EditableTable';

export default EditableTable;