import { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { Datepicker, Button } from 'flowbite-react';
import { motion, AnimatePresence } from 'framer-motion';
import toast from "react-hot-toast";
import { useAuth } from "../../hooks/useAuth";
import { createClient } from '@supabase/supabase-js';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { supabaseClient } from '../../config/supabase-clients';
import { apiVacationCount } from '../../api/apiVacationCount';
import NumberFlow from '@number-flow/react'

const supabase = supabaseClient;

const RequestVacation = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userVacations, setUserVacations] = useState([]);
    const [userVacationsNextYear, setUserVacationsNextYear] = useState([]);
    const [festivos, setFestivos] = useState([]);
    const { userPublicData } = useAuth();
    const [showNextYear, setShowNextYear] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [pendingVacationRequest, setPendingVacationRequest] = useState(null);


    const toggleNextYear = () => {
        setShowNextYear(!showNextYear);
    };

    const [records, setRecords] = useState([]);

    useEffect(() => {

        const fetchFestivos = async () => {
            const { data, error } = await supabase
                .from('holidays')
                .select('*');

            if (error) {
                console.error('Error fetching holidays:', error);
                toast.error('Error al obtener los festivos', error);
            } else {
                setFestivos(data);
            }
        }

        fetchFestivos();

    }, []);

    const fetchVacationBalance = async () => {
        // Seleccionar las de este año (columna year)
        const { data, error } = await supabase
            .from('vacation_balance')
            .select('total_days, used_days, remaining_days')
            .eq('year', new Date().getFullYear())
            .eq('user_id', userPublicData.user_id);

        if (error) {
            toast.error('Error al obtener el balance de vacaciones');
        } else {
            setUserVacations(data.map(item => ({
                totalVacations: item.total_days,
                usedVacations: item.used_days,
                remainingVacations: item.remaining_days

            })));
        }

        // Buscar las vacaciones del siguiente año
        const { data: dataNextYear, error: errorNextYear } = await supabase
            .from('vacation_balance')
            .select('total_days, used_days, remaining_days')
            .eq('year', new Date().getFullYear() + 1)
            .eq('user_id', userPublicData.user_id);

        if (errorNextYear) {
            toast.error('Error al obtener el balance de vacaciones');
        } else {
            setUserVacationsNextYear(dataNextYear.map(item => ({
                totalVacations: item.total_days,
                usedVacations: item.used_days,
                remainingVacations: item.remaining_days
            })));
        }
    };


    const fetchVacationRecords = async () => {
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1).toISOString();
        // Vacaciones de este año y del siguiente
        const endOfYear = new Date(now.getFullYear() + 1, 11, 31, 23, 59, 59).toISOString();

        const { data, error } = await supabase
            .from('vacations')
            .select('*')
            .order('start_date', { ascending: false })
            .eq('user_id', userPublicData.user_id)
            .gte('start_date', startOfYear)
            .lte('start_date', endOfYear);


        if (error) {
            console.error('Error fetching vacation records:', error);
            toast.error('Error al obtener los registros de vacaciones');
        } else {
            const parsedData = data.map(record => ({
                ...record,
                startDate: new Date(record.start_date),
                endDate: new Date(record.end_date),

            }));
            setRecords(parsedData);
        }
    };

    useEffect(() => {
        fetchVacationBalance();
        fetchVacationRecords();
    }, [userPublicData.user_id]);

    const countBusinessDays = (startDate, endDate) => {
        let count = 0;
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dayOfWeek = currentDate.getDay();
            // Ver que numero tiene cada día de la semana
            if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Excluir domingos (0) y sábados (6)
                count++;
            }

            // Excluir festivos rescatados del useState en el rango de fechas a no ser que estén en fin de semana
            festivos.forEach(festivo => {
                if (currentDate.toISOString().split('T')[0] === festivo.holiday_date && dayOfWeek !== 0 && dayOfWeek !== 6) {
                    count--;
                }
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return count;
    };

    const handleSubmit = async () => {
        const businessDays = countBusinessDays(new Date(startDate), new Date(endDate));
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const isEndOfYear = currentMonth >= 10; // November (10) or December (11)
        
        const requestStartYear = new Date(startDate).getFullYear();
        const requestEndYear = new Date(endDate).getFullYear();
    
        const existingRecord = records.find(record => {
            const recordStart = dayjs(record.startDate);
            const recordEnd = dayjs(record.endDate);
            const newStart = dayjs(startDate);
            const newEnd = dayjs(endDate);
    
            return (newStart.isBetween(recordStart, recordEnd, null, '[]') ||
                newEnd.isBetween(recordStart, recordEnd, null, '[]') ||
                recordStart.isBetween(newStart, newEnd, null, '[]') ||
                recordEnd.isBetween(newStart, newEnd, null, '[]'));
        });
    
        if (existingRecord) {
            toast.error('Ya existe un registro de ausencia que se solapa con las fechas proporcionadas');
            return;
        }
    
        if (startDate > endDate) {
            toast.error('La fecha de vuelta no puede ser anterior a la fecha de salida');
            return;
        }
    
        const remainingDaysCurrentYear = userVacations[0]?.remainingVacations || 0;
        const remainingDaysNextYear = userVacationsNextYear[0]?.remainingVacations || 0;
    
        // Si las fechas son del año siguiente, solo validamos contra remainingDaysNextYear
        if (requestStartYear > currentYear && requestEndYear > currentYear) {
            if (businessDays > remainingDaysNextYear) {
                toast.error('No tienes suficientes días de vacaciones para el año siguiente');
                return;
            }
            await submitVacationRequest(startDate, endDate);
            return;
        }
    
        // Lógica original para fechas del año actual o que cruzan años
        if (businessDays > remainingDaysCurrentYear) {
            if (!isEndOfYear) {
                toast.error('No tienes suficientes días de vacaciones restantes');
                return;
            }
    
            const daysNeededFromNextYear = businessDays - remainingDaysCurrentYear;
            if (daysNeededFromNextYear > remainingDaysNextYear) {
                toast.error('No hay suficientes días disponibles entre este año y el siguiente');
                return;
            }
            setPendingVacationRequest({
                remainingDaysCurrentYear,
                daysNeededFromNextYear,
                startDate,
                endDate
            });
            setIsConfirmDialogOpen(true);
            return;
        }
    
        await submitVacationRequest(startDate, endDate);
        fetchVacationBalance();
        fetchVacationRecords();
    };

    const submitVacationRequest = async (start, end) => {
        try {
            start.setHours(23, 59, 59, 999);
            end.setHours(23, 59, 59, 999);
            const formattedStartDate = new Date(start).toISOString().split('T')[0];
            const formattedEndDate = new Date(end).toISOString().split('T')[0];

            const { error: vacationError } = await supabase
                .from('vacations')
                .insert([{
                    user_id: userPublicData.user_id,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                    status: 'Pendientes'
                }])
                .select();

            if (vacationError) throw vacationError;

            fetchVacationBalance();
            fetchVacationRecords();
            toast.success('Solicitud de vacaciones enviada con éxito');
            setIsConfirmDialogOpen(false);
        } catch (error) {
            console.error('Error al solicitar vacaciones:', error);
            toast.error('Error al solicitar vacaciones');
            fetchVacationBalance();
            fetchVacationRecords();
        }
    };

    const handleDelete = async (index) => {
        try {
            const { error } = await supabase
                .from('vacations')
                .delete()
                .eq('id', records[index].id)
                .eq('user_id', userPublicData.user_id); 

            if (error) throw error;

            fetchVacationBalance();
            fetchVacationRecords();
            toast.success('Solicitud de vacaciones eliminada con éxito');
        } catch (error) {
            console.error('Error al eliminar solicitud de vacaciones:', error);
            toast.error('Error al eliminar solicitud de vacaciones');
        }
    };

    const filteredRecords = records.filter(record => {
        const recordYear = new Date(record.startDate).getFullYear();
        return showNextYear ? recordYear === new Date().getFullYear() + 1 : recordYear === new Date().getFullYear();
    });

    return (
        <div className="rounded-md px-4 md:px-8">
            <Dialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
                transition
                className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center min-h-screen bg-gray-500 bg-opacity-75 transition duration-300 ease-out data-[closed]:opacity-0 dark:bg-gray-900 dark:bg-opacity-75"
            >
                <div className="relative inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full dark:bg-gray-800">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 dark:bg-gray-800">
                        <DialogTitle>
                            <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                Confirmar solicitud de vacaciones
                            </h2>
                        </DialogTitle>
                        <div className="mt-3">
                            <p className="text-sm text-gray-700 dark:text-gray-300 py-2 mb-4">
                                Esta solicitud utilizará:
                                <ul className="list-disc list-inside mt-2">
                                    <li>{pendingVacationRequest?.remainingDaysCurrentYear} días del año actual</li>
                                    <li>{pendingVacationRequest?.daysNeededFromNextYear} días del próximo año</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse dark:bg-gray-800">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => submitVacationRequest(
                                pendingVacationRequest.startDate,
                                pendingVacationRequest.endDate
                            )}
                        >
                            Confirmar
                        </button>
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600 dark:hover:bg-gray-600"
                            onClick={() => setIsConfirmDialogOpen(false)}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </Dialog>
            {/* Add the year toggle button */}
            {userVacationsNextYear.length > 0 && (new Date().getMonth() === 10 || new Date().getMonth() === 11) && (
                <div className="flex justify-end mb-4">
                    <button
                        onClick={toggleNextYear}
                        className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-md transition-colors duration-200"
                    >
                        {showNextYear ? `← Año actual (${new Date().getFullYear()})` : `Año siguiente (${new Date().getFullYear() + 1}) →`}
                    </button>
                </div>
            )}

            {/* Update the statistics cards with animations */}
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-2 mb-6 w-full justify-items-center"
            >
                <motion.div
                    key={`used-${showNextYear}`}
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs"
                >
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Días utilizados</span>
                        <motion.span
                            key={showNextYear ? userVacationsNextYear[0]?.usedVacations : userVacations[0]?.usedVacations}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="text-2xl py-2 text-black dark:text-white"
                        >
                            <NumberFlow value={showNextYear ? userVacationsNextYear[0]?.usedVacations ?? 0 : userVacations[0]?.usedVacations ?? 0} />
                        </motion.span>
                    </div>
                </motion.div>

                <motion.div
                    key={`remaining-${showNextYear}`}
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                    className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs"
                >
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Días restantes</span>
                        <motion.span
                            key={showNextYear ? userVacationsNextYear[0]?.remainingVacations : userVacations[0]?.remainingVacations}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="text-2xl py-2 text-black dark:text-white"
                        >
                            <NumberFlow value={showNextYear ? userVacationsNextYear[0]?.remainingVacations ?? 0 : userVacations[0]?.remainingVacations ?? 0} />
                        </motion.span>
                    </div>
                </motion.div>

                <motion.div
                    key={`total-${showNextYear}`}
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                    className="flex items-center justify-center p-3 bg-white dark:bg-gray-800 rounded-md shadow-md w-full md:max-w-md xs:max-w-xs"
                >
                    <div className="flex flex-col items-center justify-center w-full">
                        <span className="text-sm leading-6 font-medium text-gray-400 dark:text-gray-400">Vacaciones al año</span>
                        <motion.span
                            key={showNextYear ? userVacationsNextYear[0]?.totalVacations : userVacations[0]?.totalVacations}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="text-2xl py-2 text-black dark:text-white"
                        >
                            <NumberFlow value={showNextYear ? userVacationsNextYear[0]?.totalVacations ?? 0 : userVacations[0]?.totalVacations ?? 0} />
                        </motion.span>
                    </div>
                </motion.div>
            </motion.div>

            {/* Tabla de registros de vacaciones */}
            <div className="overflow-x-auto overflow-y-hidden shadow-md relative mt-4 rounded-lg">
                <motion.table
                    key={`tableVacation-${showNextYear}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.5 }}
                    className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de inicio</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Fecha de fin</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Días usados</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Estado</th>
                            <th scope="col" className="py-3 px-4 md:px-6">Acciones</th>
                        </tr>
                    </thead>
                    <AnimatePresence>
                        <tbody>
                            {filteredRecords.length > 0 ? (
                                filteredRecords.map((registro, index) => (
                                    <motion.tr
                                        key={index + 'estado'}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -20 }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        <td className="py-4 px-4 md:px-6">{new Date(registro.startDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-4 md:px-6">{new Date(registro.endDate).toLocaleDateString()}</td>
                                        <td className="py-4 px-4 md:px-6">{countBusinessDays(registro.startDate, registro.endDate)}</td>
                                        <td className="py-4 px-4 md:px-6">
                                            {registro.status === 'Aceptadas' ? (
                                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10 dark:text-green-900 dark:bg-green-200">Aceptadas</span>
                                            ) : registro.status === 'Rechazadas' ? (
                                                <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10 dark:text-red-900 dark:bg-red-200">Rechazadas</span>
                                            ) : (
                                                <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-600 ring-1 ring-inset ring-yellow-500/10 dark:text-yellow-900 dark:bg-yellow-200">Pendientes</span>
                                            )}
                                        </td>
                                        <td className="py-4 px-4 md:px-6">
                                            {(registro.status === 'Pendientes' || registro.status === 'Rechazadas') && (
                                                <button onClick={() => handleDelete(index)} className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                                                    Eliminar
                                                </button>
                                            )}
                                        </td>
                                    </motion.tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="py-4 px-4 md:px-6 text-center">No tienes ningún periodo de vacaciones todavía</td>
                                </tr>
                            )}
                        </tbody>
                    </AnimatePresence>
                </motion.table>
            </div>

            {/* Solicitar vacaciones */}
            <h2 className='text-1xl p-2 font-semibold text-gray-500 dark:text-white mt-4 mb-2'>Solicitar vacaciones</h2>
            <div className="flex flex-col md:flex-row items-center p-4 bg-gray-100 dark:bg-gray-800 rounded-md shadow-md space-y-4 md:space-y-0 md:space-x-4">
                <Datepicker
                    language='es-ES'
                    className="w-full md:w-1/2"
                    weekStart={1}
                    showClearButton={false}
                    minDate={new Date()}

                    onSelectedDateChanged={(e) => {
                        setStartDate(e);
                    }}
                    labelTodayButton="Hoy"
                />
                <span className="text-gray-500 dark:text-gray-400 mx-2 hidden md:block"> - </span>
                <Datepicker
                    language='es-ES'
                    format="dd/MM/yyyy"
                    labelTodayButton="Hoy"
                    showClearButton={false}
                    minDate={new Date()}
                    className="w-full md:w-1/2"
                    weekStart={1}
                    onSelectedDateChanged={(e) => {
                        setEndDate(e);
                    }}
                />
                <button className="bg-primary-600 text-white px-4 py-2 rounded w-full md:w-auto" onClick={handleSubmit}>Solicitar</button>
            </div>
        </div>
    );
};

export default RequestVacation;