/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
dayjs.locale('es');
import { initFlowbite } from "flowbite";
import { Datepicker } from 'flowbite-react';
import { createClient } from '@supabase/supabase-js';
import { useAuth } from '../../hooks/useAuth';
import { useClock } from '../../hooks/useClock';
import festivos from '../../data/festivos';
import { supabaseClient } from '../../config/supabase-clients';
import { use } from 'i18next';
import toast from 'react-hot-toast';
import { set } from 'lodash';
import axios from 'axios';

const supabase = supabaseClient;


const ClockInClockOut = () => {
    const { userPublicData } = useAuth();
    const [records, setRecords] = useState([]);
    const [weekHours, setWeekHours] = useState(0);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm'));
    const [animationDirection, setAnimationDirection] = useState(0);
    const [loadingRecords, setLoadingRecords] = useState(true);
    const [downloading, setDownloading] = useState(false);
    
    const USER_ID = userPublicData.user_id;
    const { setIsWorking, isWorking, elapsedTime, handleClockInOut } = useClock();
    useEffect(() => {
    }, [userPublicData]);

    useEffect(() => {
        fetchRecordsForWeek(currentWeek);
    }, [currentWeek, userPublicData, handleClockInOut]);


    // Obtener la hora actual cada minuto
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(dayjs().format('HH:mm'));
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    const generateDateRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const fetchRecordsForWeek = async (weekOffset) => {
        try {
            const now = new Date();
            const startOfWeek = getStartOfWeek(new Date(now.setDate(now.getDate() + weekOffset * 7)));
            const endOfWeek = getEndOfWeek(startOfWeek);

            // Limitar endOfWeek al día actual si estamos en la semana actual
            const today = new Date();
            today.setHours(23, 59, 59, 999);
            const isCurrentWeek = weekOffset === 0;
            const adjustedEndOfWeek = isCurrentWeek ? new Date(Math.min(endOfWeek, today)) : endOfWeek;

            const { data: shifts, error: shiftError } = await supabase
                .from('shifts')
                .select('*')
                .gte('work_date', startOfWeek.toISOString())
                .lte('work_date', adjustedEndOfWeek.toISOString())
                .order('work_date', { ascending: true })
                .eq('user_id', USER_ID);

            if (shiftError) throw shiftError;

            const [vacationsResponse, absencesResponse] = await Promise.all([
                supabase.from('vacations').select('*')
                    .gte('start_date', startOfWeek.toISOString())
                    .lte('end_date', adjustedEndOfWeek.toISOString())
                    .eq('user_id', USER_ID),
                supabase.from('absence').select('*')
                    .gte('start_date', startOfWeek.toISOString())
                    .lte('end_date', adjustedEndOfWeek.toISOString())
                    .eq('user_id', USER_ID)
            ]);

            const { data: vacations = [], error: vacationError } = vacationsResponse;
            const { data: absences = [], error: absenceError } = absencesResponse;

            if (vacationError) console.error('Error fetching vacations:', vacationError);
            if (absenceError) console.error('Error fetching absences:', absenceError);

            const weekDates = generateDateRange(startOfWeek, adjustedEndOfWeek);

            const combinedRecords = weekDates.map(date => {
                const formattedDate = dayjs(date).startOf('day').toDate();
                const shiftsForDate = shifts.filter(shift => dayjs(shift.work_date).isSame(formattedDate, 'day'));
            
                return {
                    day: formattedDate,
                    shifts: shiftsForDate.map(({ shift_id, shift_start, shift_end }) => ({
                        shift_id, start: shift_start, end: shift_end
                    })),
                    absence: shiftsForDate.length ? shiftsForDate[0].absence : '',
                    totalHours: calculateTotalHours(shiftsForDate) // Pasar directamente el array de turnos
                };
            });

            updateAbsencesAndHolidays(combinedRecords, vacations, absences);
            recalculateHours(combinedRecords);

            setRecords(combinedRecords.reverse());
            setLoadingRecords(false);
        } catch (error) {
            console.error('Error fetching records:', error);
            setLoadingRecords(false);
        }
    };

    const getTodayDayOfWeekInteger = () => {
        //Lunes = 1, Domingo = 7
        const today = new Date().getDay();
        return today === 0 ? 7 : today;

    };

    // Función para actualizar los registros con vacaciones, ausencias y festivos
    const updateAbsencesAndHolidays = (records, vacations, absences) => {
        records.forEach(record => {
            const { day: recordDate } = record;

            // Check si está en vacaciones
            if (isInRange(vacations, recordDate)) {
                record.absence = 'Vacaciones';
            } else if (isInRange(absences, recordDate)) {
                // Si no está de vacaciones, verificar ausencias
                record.absence = getAbsenceReason(absences, recordDate);
            }

            // Verificar si es festivo
            if (isHoliday(recordDate)) {
                record.absence = 'Festivo';
            }
        });
    };

    // Función para verificar si una fecha está dentro de un rango
    const isInRange = (events, date) => {
        return events.some(({ start_date, end_date }) => {
            const startDate = normalizeDate(new Date(start_date));
            const endDate = normalizeDate(new Date(end_date), true);
            return date >= startDate && date <= endDate;
        });
    };

    // Función para obtener la razón de ausencia
    const getAbsenceReason = (absences, date) => {
        const absence = absences.find(({ start_date, end_date }) => {
            const startDate = normalizeDate(new Date(start_date));
            const endDate = normalizeDate(new Date(end_date), true);
            return date >= startDate && date <= endDate;
        });
        return absence ? absence.reason : '';
    };

    // Función para normalizar las fechas a medianoche
    const normalizeDate = (date, endOfDay = false) => {
        if (endOfDay) {
            date.setHours(23, 59, 59, 999);
        } else {
            date.setHours(0, 0, 0, 0);
        }
        return date;
    };

    // Función para verificar si una fecha es festivo
    const isHoliday = (date) => {
        return festivos.some(festivo => normalizeDate(new Date(festivo.fecha)).getTime() === date.getTime());
    };


    useEffect(() => {
        initFlowbite();
    }, []);

    const updateLocalState = (newRecords) => {
        recalculateHours(newRecords);
        setRecords(newRecords);
    };

    const handleSupabaseError = (error, action) => {
        if (error) {
            console.error(`Error ${action} el registro:`, error);
        }
    };

    const updateShiftInSupabase = async (userId, workDate, shiftId, shift) => {
        const { data, error } = await supabase
            .from('shifts')
            .update({
                shift_start: shift.start ? shift.start : null,
                shift_end: shift.end ? shift.end : null,
            })
            .eq('user_id', userId)
            .eq('work_date', workDate)
            .eq('shift_id', shiftId)
            .select();
        handleSupabaseError(error, 'actualizando');
    };

    const insertShiftInSupabase = async (userId, employerName, now) => {
        const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format    
        // Insert the new shift
        const { data, error } = await supabase
            .from('shifts')
            .insert({
                user_id: userId,
                employer_name: employerName,
                work_date: today,
                shift_start: now,
                shift_end: null,
            });

        handleSupabaseError(error, 'insertando');
    };

    const handleDeleteShift = async (dayIndex, shiftIndex) => {
        const newRecords = [...records];

        // Check if the record and shift exist
        if (newRecords[dayIndex] && newRecords[dayIndex].shifts[shiftIndex]) {
            const shiftId = newRecords[dayIndex].shifts[shiftIndex].shift_id;

            // Remove the shift from the local state
            newRecords[dayIndex].shifts.splice(shiftIndex, 1);

            // Recalculate total hours for the day
            newRecords[dayIndex].totalHours = calculateTotalHours(newRecords[dayIndex]);

            // Update local state
            setRecords(newRecords);

            // Delete the shift from Supabase
            try {
                await deleteShiftFromSupabase(shiftId);
                toast.success('Turno eliminado');
            } catch (error) {
                console.error('Error deleting shift:', error);
                toast.error('No se ha podido eliminar el turno');
                // Refresh the current week's data
                await fetchRecordsForWeek(currentWeek);
            }
        } else {
            console.error('Shift not found at index:', dayIndex, shiftIndex);
            toast.error('Shift not found');
        }
    };

    const deleteShiftFromSupabase = async (shiftId) => {
        const { data, error } = await supabase
            .from('shifts')
            .delete()
            .eq('shift_id', shiftId);
        if (error) {
            throw error;
        }
        return data;
    };

    const handleShiftChange = async (index, shiftIndex, type, value) => {
        const newRecords = [...records];
        newRecords[index].shifts[shiftIndex][type] = value;
        updateLocalState(newRecords);

        const record = newRecords[index];
        const shift = record.shifts[shiftIndex];
        const workDate = dayjs(record.day).format('YYYY-MM-DD');
        const shiftId = shift.shift_id;

        await updateShiftInSupabase(USER_ID, workDate, shiftId, shift);
    };

    const handleAddShift = async (index) => {
        const newRecords = [...records];

        //Comprobar que no haya más de 4 turnos al día
        if (newRecords[index].shifts.length > 3) {
            toast.error('No se pueden añadir más de 4 turnos al día');
            return;
        }
        newRecords[index].shifts.push({ start: '', end: '' });
        updateLocalState(newRecords);
        const now = dayjs().format('HH:mm');
        await insertShiftInSupabase(USER_ID, userPublicData.first_name, now);
        // Actualizar el estado local con el nuevo turno
        fetchRecordsForWeek(currentWeek);

    };

    const handleAbsenceChange = async (index, value) => {
        const newRecords = [...records];
        const record = newRecords[index];
        const workDate = dayjs(record.day).format('YYYY-MM-DD');
        const absence = value === 'Ninguno' ? null : value;

        if (value === 'Vacaciones') {
            // Comprobar si ya existe un periodo de vacaciones que incluya esta fecha
            const { data: vacationOverlap } = await supabase
                .from('vacations')
                .select('*')
                .eq('user_id', USER_ID)
                .lte('start_date', workDate)
                .gte('end_date', workDate);

            if (vacationOverlap && vacationOverlap.length > 0) {
                toast.error('Ya existe un periodo de vacaciones que incluye esta fecha');
                return;
            }

            // Obtener el balance de vacaciones actual
            const { data: vacationBalance, error: balanceError } = await supabase
                .from('vacation_balance')
                .select('*')
                .eq('user_id', USER_ID)
                .single();

            if (balanceError) {
                toast.error('Error al obtener el balance de vacaciones');
                return;
            }

            if (vacationBalance.remaining_days <= 0) {
                toast.error('No tienes días de vacaciones disponibles');
                return;
            }

            // Si hay días disponibles, proceder con la creación del registro de vacaciones
            const { error: insertError } = await supabase
                .from('vacations')
                .insert({
                    user_id: USER_ID,
                    start_date: workDate,
                    end_date: workDate,
                    status: 'Pendientes',
                });

            if (insertError) {
                toast.error('Error al registrar las vacaciones');
                return;
            }

            // Actualizar el balance de vacaciones
            const { error: updateError } = await supabase
                .from('vacation_balance')
                .update({
                    used_days: vacationBalance.used_days + 1,
                    remaining_days: vacationBalance.remaining_days - 1
                })
                .eq('user_id', USER_ID);

            if (updateError) {
                toast.error('Error al actualizar el balance de vacaciones');
                return;
            }

            toast.success('Día de vacaciones registrado correctamente');
        } else {
            // Manejar otros tipos de ausencias
            const { data: existingAbsence } = await supabase
                .from('absence')
                .select('*')
                .eq('user_id', USER_ID)
                .eq('start_date', workDate)
                .single();

            if (absence === null && existingAbsence) {
                await supabase
                    .from('absence')
                    .delete()
                    .eq('sick_leave_id', existingAbsence.sick_leave_id);
                toast.success('Ausencia eliminada correctamente');
            } else if (existingAbsence) {
                await supabase
                    .from('absence')
                    .update({ reason: absence })
                    .eq('sick_leave_id', existingAbsence.sick_leave_id);
                toast.success('Ausencia actualizada correctamente');
            } else if (absence !== null) {
                await supabase
                    .from('absence')
                    .insert({
                        user_id: USER_ID,
                        start_date: workDate,
                        end_date: workDate,
                        reason: absence,
                    });
                toast.success('Ausencia registrada correctamente');
            }
        }

        newRecords[index].absence = value;
        updateLocalState(newRecords);
    };

    const recalculateHours = (newRecords) => {
        newRecords.forEach(record => {
            const totalMinutes = calculateTotalHours(record.shifts);
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            record.totalHours = `${hours}h ${minutes}m`;
        });
    };

    const calculateTotalHours = (shifts) => {
        if (!Array.isArray(shifts)) {
            throw new TypeError('shifts.reduce is not a function');
        }
        return shifts.reduce((totalMinutes, shift) => {
            if (shift.start && shift.end) {
                const [startHours, startMinutes] = shift.start.split(':').map(Number);
                const [endHours, endMinutes] = shift.end.split(':').map(Number);
                const startDate = new Date(0, 0, 0, startHours, startMinutes, 0);
                const endDate = new Date(0, 0, 0, endHours, endMinutes, 0);
                return totalMinutes + (endDate - startDate) / (1000 * 60);
            }
            return totalMinutes;
        }, 0);
    };

    const isWeekend = (date) => {
        const day = date.getDay();
        return day === 0 || day === 6;
    };

    const getAbsenceClass = (absence) => {
        switch (absence) {
            case 'Vacaciones':
                return 'bg-green-50 text-green-600 ring-green-500/10 dark:text-green-900 dark:bg-green-200';
            case 'Baja médica':
                return 'bg-red-50 text-red-600 ring-red-500/10 dark:text-red-900 dark:bg-red-200';
            case 'Permiso retribuido':
                return 'bg-yellow-50 text-yellow-600 ring-yellow-500/10 dark:text-yellow-900 dark:bg-yellow-200';
            case 'Otras causas':
                return 'bg-blue-50 text-blue-600 ring-blue-500/10 dark:text-blue-900 dark:bg-blue-200';
            case 'Festivo':
                return 'bg-orange-50 text-orange-600 ring-orange-500/10 dark:text-orange-900 dark:bg-orange-200';
            default:
                return 'bg-gray-50 text-gray-600 ring-gray-500/10 dark:text-gray-500 dark:bg-gray-700';
        }
    };

    const getStartOfWeek = (date) => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        const startOfWeek = new Date(date.setDate(diff));
        startOfWeek.setHours(0, 0, 0, 0);
        return startOfWeek;
    };

    const getEndOfWeek = (date) => {
        const startOfWeek = getStartOfWeek(new Date(date));
        const endOfWeek = new Date(startOfWeek.setDate(startOfWeek.getDate() + 6));
        endOfWeek.setHours(23, 59, 59, 999);
        return endOfWeek;
    };

    //Calculo de horas de la semana actual
    useEffect(() => {
        let totalMinutes = 0;
        records.forEach(record => {
            totalMinutes += record.shifts.reduce((acc, shift) => {
                if (shift.start && shift.end) {
                    const [startHours, startMinutes] = shift.start.split(':').map(Number);
                    const [endHours, endMinutes] = shift.end.split(':').map(Number);
                    const startDate = new Date(0, 0, 0, startHours, startMinutes, 0);
                    const endDate = new Date(0, 0, 0, endHours, endMinutes, 0);
                    const diffMinutes = (endDate - startDate) / (1000 * 60);
                    return acc + diffMinutes;
                }
                return acc;
            }

                , 0);
        });
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        setWeekHours(`${hours}h ${minutes}m`);
    }, [records]);

    const formatDate = (date) => {
        return dayjs(date).format('D MMMM YYYY');
    };

    const handlePreviousWeek = () => {
        setAnimationDirection(-1);
        setCurrentWeek(prevWeek => prevWeek - 1);
    };

    const handleNextWeek = () => {
        setAnimationDirection(1);
        setCurrentWeek(prevWeek => prevWeek + 1);
    };

    const handleDateChange = (date) => {
        const selectedDate = new Date(date);
        const startOfWeek = getStartOfWeek(selectedDate);
        const currentDate = new Date();
        const startOfWeekCurrent = getStartOfWeek(currentDate);

        const diffInWeeks = Math.floor((startOfWeek - startOfWeekCurrent) / (7 * 24 * 60 * 60 * 1000));
        setCurrentWeek(diffInWeeks);
    };

    const downloadUserShifts = async (userId) => {
        try {
            setDownloading(true);
            // Llamar a escenario de n8n para descargar el archivo csv que genera el propio n8n
            const response = await axios.get('https://n8n.coventia.es/webhook/31ed34aa-488c-4a0a-abc2-48a2d7521cc2', {
                responseType: 'blob', // Importante para manejar el archivo binario
                params: {
                    user_id: userId,
                },
            });

            // Descargar el archivo csv generado por n8n
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'shifts.xls');
            setDownloading(false);
            console.log('Archivo descargado exitosamente');
            toast.success('Archivo descargado exitosamente');

        } catch (error) {
            console.error('Error descargando los turnos:', error);
            toast.error('Error descargando los turnos');
            setDownloading(false);
        }
    };


    const startOfWeek = getStartOfWeek(new Date(new Date().setDate(new Date().getDate() + currentWeek * 7)));
    const endOfWeek = getEndOfWeek(startOfWeek);

    return (
        <div>
            <div className="flex flex-wrap justify-center mb-10">
                <div
                    className={`transition ease-in-out delay-150 rounded-lg w-1/2 md:w-1/2 lg:w-1/6 hover:-translate-y-1 hover:scale-105 duration-300 cursor-pointer my-5 text-white ${isWorking ? 'bg-red-500 dark:bg-red-800' : 'bg-primary-500 dark:bg-primary-700'} py-1`}
                    onClick={handleClockInOut}
                >
                    <div className="flex justify-center align-center text-3xl my-3">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path>
                            <polyline points="10 17 15 12 10 7"></polyline>
                            <line x1="15" y1="12" x2="3" y2="12"></line>
                        </svg>
                    </div>
                    <div className="flex align-middle justify-center text-xl font-medium mb-1">{currentTime}</div>
                    <div className="flex text-xs justify-center align-middle font-normal mb-3">
                        {isWorking ? `Tiempo trabajado: ${elapsedTime}` : 'Fichar'}
                    </div>
                </div>
            </div>


            {userPublicData ? (
                <div className="flex justify-end mr-16 mt-4 mb-6">
                    <button
                        className={`bg-primary-600 text-white py-2 px-4 rounded ${downloading ? 'cursor-not-allowed bg-gray-400' : 'hover:bg-primary-500'}`}
                        style={{ pointerEvents: downloading ? 'none' : 'auto' }}
                        onClick={() => downloadUserShifts(USER_ID)}
                    >
                        {downloading ? "Descargando..." : `Descargar turnos`}
                    </button>
                </div>
            ) : null
            }


            <div className="flex align-middle justify-center">
                <motion.div
                    key={currentWeek}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}

                    className='text-black  dark:text-gray-300'>
                    Semana del {formatDate(startOfWeek)} al {formatDate(endOfWeek)}

                </motion.div>

            </div>
            <div className="flex align-middle justify-center my-2">
                <motion.div
                    key={currentWeek}
                    initial={{ opacity: 0, x: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}

                    className='text-black  dark:text-gray-300'>
                    Horas trabajadas: {weekHours}
                </motion.div>
            </div>
            <div className="flex px-20 align-end justify-end">
                <Datepicker
                    language='es-ES'
                    weekStart={1}
                    onSelectedDateChanged={handleDateChange}
                    showClearButton={false}
                    labelTodayButton='Hoy'

                />
            </div>
            <div className="flex items-center relative">
                <div className='hidden md:block transition ease-in-out hover:-translate-y-1 hover:scale-110 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 h-56 rounded-l-full mx-1'>
                    <button
                        onClick={handlePreviousWeek}
                        className="left-0 z-10 align flex items-center justify-center h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>
                </div>
                <div className="overflow-x-hidden shadow-md w-full relative mt-4 rounded-xl">
                    <AnimatePresence mode='wait'>
                        <motion.div
                            key={currentWeek}
                            initial={{ opacity: 0, x: animationDirection * 40 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: animationDirection * -40 }}
                            transition={{ duration: 0.2 }}

                            className='overflow-y-hidden'>
                            <table
                                className="w-full rounded-lg text-sm text-left text-gray-500 dark:text-gray-400"
                            >
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope='col' className="py-3 px-4 md:px-6">Día</th>
                                        <th scope='col' className="py-3 px-4 md:px-6">Turnos trabajados</th>
                                        <th scope='col' className="py-3 px-4 md:px-6">Ausencias</th>
                                        <th scope='col' className="py-3 px-4 md:px-6">Horas totales</th>
                                    </tr>
                                </thead>
                                <motion.tbody
                                    className="bg-white dark:bg-gray-700 divide-y divide-gray-200 dark:divide-gray-600 rounded-xl overflow-y-hidden"
                                >
                                    {loadingRecords ? (
                                        [...Array(getTodayDayOfWeekInteger())].map((_, index) => (
                                            <tr key={index}>
                                                {/* Skeleton para la columna Día */}
                                                <td className="px-6 py-4">
                                                    <div className="h-full bg-gray-200 dark:bg-gray-500 rounded mb-2 w-3/4 animate-pulse"></div>
                                                    <div className="h-3 bg-gray-200 dark:bg-gray-500 rounded w-1/2 animate-pulse"></div>
                                                </td>
                                                {/* Skeleton para Turnos trabajados */}
                                                <td className="px-4 py-4">
                                                    <div className="h-4 bg-gray-200 dark:bg-gray-500 rounded mb-2 w-full animate-pulse"></div>
                                                </td>
                                                {/* Skeleton para Ausencias */}
                                                <td className="px-6 py-4">
                                                    <div className="h-4 bg-gray-200 dark:bg-gray-500 rounded mb-2 w-3/4 animate-pulse"></div>
                                                </td>
                                                {/* Skeleton para Horas Totales */}
                                                <td className="px-6 py-4">
                                                    <div className="h-4 bg-gray-200 dark:bg-gray-500 rounded mb-2 w-1/2 animate-pulse"></div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (

                                        records.map((record, index) => (
                                            <tr key={index} className={isWeekend(record.day) ? 'bg-gray-100 border-b dark:bg-gray-700 dark:border-gray-900' : 'bg-white border-b dark:bg-gray-800 dark:border-gray-700'}>
                                                <td className="align-top px-6 py-4 whitespace-nowrap border-b dark:border-gray-700">
                                                    <div className='text-xl text-gray-800 dark:text-gray-300'>
                                                        {`${dayjs(record.day).format('D')} ${dayjs(record.day).format('MMM').replace(/^(.)/, match => match.toUpperCase())}`}
                                                    </div>
                                                    <div className="text-sm text-gray-500">{dayjs(record.day).format('dddd')}</div>
                                                </td>

                                                <motion.td
                                                    key={'shifts' + index}
                                                    className="px-4 py-2 border-b dark:border-gray-700"
                                                >
                                                    {record.shifts.length > 0 ? (
                                                        record.shifts
                                                            .slice() // Crear una copia para no mutar el array original
                                                            .map((shift, shiftIndex) => (
                                                                <motion.div
                                                                    key={shiftIndex + '-' + index}
                                                                    initial={{ opacity: 0, x: 0 }}
                                                                    animate={{ opacity: 1, x: 0 }}
                                                                    exit={{ opacity: 0, y: 20 }}
                                                                    transition={{ duration: 0.5 }}
                                                                    className="my-2 flex flex-col md:max-w-[20rem] sm:flex-row sm:justify-between sm:items-center gap-2 py-1">
                                                                    <div className="flex-1">
                                                                        <div className="relative">
                                                                            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                                                <svg className={`w-4 h-4 ${isWorking ? 'text-gray-300 dark:text-gray-600' : 'text-gray-500 dark:text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd" />
                                                                                </svg>
                                                                            </div>
                                                                            <input
                                                                                type="time"
                                                                                id={`start-time-${index}-${shiftIndex}`}
                                                                                value={shift.start}
                                                                                onChange={(e) => handleShiftChange(index, shiftIndex, 'start', e.target.value)}
                                                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:text-gray-400"
                                                                                min="08:00"
                                                                                max="19:00"
                                                                                required
                                                                                disabled={new Date(record.day) < new Date().setHours(0, 0, 0, 0) || isWorking}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-1">
                                                                        <div className="relative">
                                                                            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                                                                <svg className={`w-4 h-4 ${isWorking ? 'text-gray-300 dark:text-gray-600' : 'text-gray-500 dark:text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clipRule="evenodd" />
                                                                                </svg>
                                                                            </div>
                                                                            <input
                                                                                type="time"
                                                                                id={`end-time-${index}-${shiftIndex}`}
                                                                                value={shift.end}
                                                                                onChange={(e) => handleShiftChange(index, shiftIndex, 'end', e.target.value)}
                                                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:text-gray-400"
                                                                                min="07:00"
                                                                                max="20:00"
                                                                                required
                                                                                disabled={new Date(record.day) < new Date().setHours(0, 0, 0, 0) || isWorking}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center justify-end">
                                                                        {new Date(record.day).toDateString() === new Date().toDateString() && (
                                                                            <button
                                                                                onClick={() => handleDeleteShift(index, shiftIndex)}
                                                                                disabled={record.shifts.length === 1 || isWorking}
                                                                                className="text-red-500 hover:text-red-700 disabled:text-gray-400 py-2"
                                                                            >
                                                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                                                                </svg>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </motion.div>
                                                            ))
                                                    ) : (
                                                        <div className="flex items-center justify-start">
                                                            <span className="text-gray-500 dark:text-gray-400">No hay turnos</span>
                                                        </div>
                                                    )}
                                                    {new Date(record.day).toDateString() === new Date().toDateString() && (
                                                        <button
                                                            onClick={() => handleAddShift(index)}
                                                            className="text-blue-500 hover:text-blue-700 disabled:text-gray-400 py-2"
                                                            disabled={isWorking}
                                                        >
                                                            Añadir turno
                                                        </button>
                                                    )}
                                                </motion.td>

                                                <td className="px-6 py-4 whitespace-nowrap border-b dark:border-gray-700">
                                                    {new Date(record.day).toDateString() === new Date().toDateString() ? (
                                                        <select
                                                            value={record.absence}
                                                            onChange={(e) => handleAbsenceChange(index, e.target.value)}
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        >
                                                            <option value="Ninguno">-</option>
                                                            <option value="Vacaciones">Vacaciones</option>
                                                            <option value="Baja médica">Baja médica</option>
                                                            <option value="Permiso retribuido">Permiso retribuido</option>
                                                            <option value="Otras causas">Otras causas</option>
                                                            <option value="Festivo">Festivo</option>
                                                        </select>
                                                    ) : (
                                                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-md font-medium ring-1 ring-inset ${getAbsenceClass(record.absence)}`}>
                                                            {record.absence || '-'}
                                                        </span>
                                                    )}
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap border-b dark:border-gray-700">
                                                    {record.totalHours || '0h 0m'}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </motion.tbody>

                            </table>
                        </motion.div>
                    </AnimatePresence>
                </div >
                <div className='hidden md:block transition ease-in-out hover:-translate-y-1 hover:scale-110 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 h-56 rounded-r-full mx-1'>
                    <button
                        onClick={handleNextWeek}
                        disabled={currentWeek == 0}
                        className="right-0 z-10 flex items-center justify-center h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>
            </div>
            {/* Paginador para el movil justo debajo de la tabla con los 2 botones*/}
            <div className="flex justify-end items-center md:hidden mt-5">
                <div className='flex md:hidden w-1/2 h-10 bg-primary-50 dark:bg-gray-600 dark:hover:bg-gray-700 hover:bg-primary-50 duration-300 rounded-l-full mx-2 justify-center'>
                    <button
                        onClick={handlePreviousWeek}
                        onTouchEnd={handlePreviousWeek}
                        disabled={currentWeek == 0}
                        className="left-0 w-full z-10 flex items-center justify-center h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                        </svg>
                    </button>
                </div>
                <div className='flex md:hidden w-1/2 bg-primary-50 dark:bg-gray-600 h-10 dark:hover:bg-gray-700 hover:bg-primary-50 dark:hover-bg-gray-600 duration-300 rounded-r-full mx-2  justify-center'>
                    <button
                        onClick={handleNextWeek}
                        onTouchEnd={handleNextWeek}
                        disabled={currentWeek == 0}
                        className="z-10 w-full flex justify-center mt-2 h-full px-4 focus:outline-none"
                    >
                        <svg className="w-6 h-6 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div >
    );
};

export default ClockInClockOut;
