import ClockInClockOut from './ClockInClockOut';
import RequestVacation from './RequestVacation';
// import EmployeeRecords from './EmployeeRecords';
import { RequestAbsence } from './RequestAbsence';
import { XCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { ManageUsersClock } from './admin/ManageUsers';
import { ManageVacation } from './admin/ManageVacation';
import { Tabs } from 'flowbite-react';
import { VacationCalendar } from './VacationCalendar';
import { AbsenceCalendar } from './AbsenceCalendar';
import { GetUsuarioXCorreduria } from '../../data/GetUsuarioXCorreduria';
import { HolidayManager } from './HolidayManager';
import { ManageVacationCount } from './admin/ManageVacationCount';
import { TeleWorking } from './TeleWorking';

const Dashboard = () => {
    const { t } = useTranslation();
    const { correduria, userPublicData } = useAuth();
    const [notAllowed, setNotAllowed] = useState(true);
    const [isStaff, setIsStaff] = useState(false);

    useEffect(() => {
        if (correduria.name === 'coventia') {
            setNotAllowed(false);
        } else {
            setNotAllowed(true);
        }
    }, [correduria.name, userPublicData]);

    useEffect(() => {
        GetUsuarioXCorreduria().then((data) => {
            if (data.staff) {
                setIsStaff(true);
            }
        });
        document.title = "Coventia Office | Panel de empleado"
    }, []);

    if (notAllowed) {
        return (
            <div className="flex justify-center items-center h-96">
                <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <XCircleIcon className="w-12 h-12 text-gray-400 dark:text-gray-600" />
                        <span className="text-gray-400 dark:text-gray-600">{t('No tienes permiso para acceder a esta página')}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div >
            {/* Div contenedor del los componentes para empleados */}
            <div className="max-w-full mx-2 py-3 sm:mx-auto sm:6 lg:px-2 overflow-x-hidden">
                <h1 className="text-2xl font-bold mb-4 dark:text-white">Sistema de gestión de empleados</h1>
                {/* Registro de jornadas */}
                <div className="mb-14">
                    <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white">Registro de jornadas 🕑</h1>
                    <ClockInClockOut />
                </div>

                {/* Panel de teletrabajo para empleados */}
                <div className="mb-10">
                    <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white">Panel de teletrabajo 🏠</h1>
                    <TeleWorking />
                </div>

                {/* Panel de vacaciones */}
                <div className="mb-10">
                    <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white mb-3">Panel de vacaciones 🏖️</h1>
                    <RequestVacation />
                </div>

                {/* Panel de ausencias */}
                <div className="mb-10">
                    <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white">Panel de ausencias 😶‍🌫️</h1>
                    <RequestAbsence />
                </div>
            </div>

            {/* Div contenedor para los paneles admin */}
            {isStaff && (

                <div className='max-w-full mx-2 py-3 sm:mx-auto sm:6 lg:px-2 overflow-x-auto'>
                    <div className="mb-10">
                        <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white">Panel de administración 🛠️</h1>
                        <ManageUsersClock />
                    </div>

                    <div className="mb-10">
                        <Tabs>
                            <Tabs.Item title="Vacaciones pendientes">
                                <div>
                                    <h1 className="text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white">Gestor de vacaciones</h1>
                                    <ManageVacation />
                                </div>
                            </Tabs.Item>
                            <Tabs.Item title="Vacaciones de los empleados">
                                <h1 className='text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white'>
                                    <VacationCalendar />
                                </h1>
                            </Tabs.Item>
                            <Tabs.Item title="Ausencias de los empleados">
                                <h1 className='text-1xl font-semibold px-2 mt-4 text-gray-900 dark:text-white'>
                                    <AbsenceCalendar />
                                </h1>
                            </Tabs.Item>
                            <Tabs.Item title="Gestionar festivos">
                                <div className='px-2 mt-4 text-gray-900 dark:text-white'>
                                    <HolidayManager />
                                </div>
                            </Tabs.Item>
                            <Tabs.Item title="Gestionar vacaciones">
                                <div className='px-2 mt-4 text-gray-900 dark:text-white'>
                                    <ManageVacationCount />
                                </div>
                            </Tabs.Item>
                        </Tabs>
                    </div>
                </div>
            )}

        </div>

    );
};

export default Dashboard;
